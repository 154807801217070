import React from "react";
import Layout from "../../components/Layout";
import { Container } from "react-bootstrap";
import SEO from "../../components/Seo";
import HeroBanner from "../../components/HeroBanner";
import { graphql, PageProps } from "gatsby";
import {
  ImageInterface,
  LandingSectionsInterface,
  LocalizedContextInterface,
  PortableText
} from "../../types/SanityTypes";
import { useSiteMetadata } from "../../hooks/useSiteMetadata";
import StructuredData from "../../components/StructuredData";
import { PageModalInterface, Seo } from "../../components/PageModal";
import { LocalisedPageContext } from "../../types/PageTypes";
import ProductCategorySearch from "../../components/ProductCategorySearch";
import BreadCrumbs from "../../components/BreadCrumbs";

export type CollectionPageQueryProps = {
  collectionPage: {
    parentCategory: any;
    seo: Seo;
    name: string;
    slug: {
      current: string;
    };
    collectionId: string;
    _rawIntroduction: PortableText;
    _rawPaImage: ImageInterface;
    pageModal?: PageModalInterface;
    landingSections: LandingSectionsInterface[];
    // For Preview
    introduction?: PortableText;
    paImage?: ImageInterface;
    title?: string;
    description?: string;
    paDescription?: string;
  };
} & LocalizedContextInterface;

type CollectionPageContext = LocalisedPageContext;

export const data = graphql`
  query collectionPage($_id: String, $language: String) {
    collectionPage: sanityCollection(_id: { eq: $_id }) {
      ...ProductCollectionPageFieldsFull
    }
    ...LocalizedContext
  }
`;

const ProductCollectionPage = (props: PageProps<CollectionPageQueryProps, CollectionPageContext>) => {
  const { htmlLang } = useSiteMetadata();
  const productCollectionPage = props.data.collectionPage;

  const pageTitle = productCollectionPage.seo?.metaTitle || productCollectionPage.title;
  const pageDescription = productCollectionPage.paDescription || productCollectionPage.description;
  // This is to make sure banner works of internationalisation
  const title = productCollectionPage.title;
  const image = productCollectionPage?.paImage;
  const sanityImage = productCollectionPage?.paImage;
  const imageWidth = image?.asset?.metadata?.dimensions?.width;
  const imageHeight = image?.asset?.metadata?.dimensions?.height;
  const imageMimeType = image?.asset?.mimeType;

  const parentPagePath = `${productCollectionPage?.parentCategory?.name || ""}/${
    productCollectionPage?.parentCategory?.slug.current || ""
  }`;
  const currentPagePath = `${productCollectionPage.name}/${productCollectionPage.slug.current}`;

  const filteredProducts: any[] = [];
  props.pageContext.prefetchData.data.allSanityProduct.nodes.map((product: any) => {
    product.productCollection.map((category: any) => {
      if (category.collectionId == props.data.collectionPage.collectionId) {
        filteredProducts.push(product);
      }
    });
  });

  return (
    <Layout
      localizedContext={{ ...props.pageContext, ...props.data }}
      pageName={pageTitle}
      pageType={"collection"}
      analyticsTagMeta={{
        category: productCollectionPage?.name,
        promoId: "4"
      }}
    >
      <BreadCrumbs className="hidden" parentPagePath={parentPagePath} currentPagePath={currentPagePath} />
      <StructuredData
        type={"WebPage"}
        name={title}
        description={pageDescription}
        data={productCollectionPage.paImage}
        language={htmlLang}
        slug={`/${productCollectionPage?.slug.current}/`}
      />
      <SEO
        title={title}
        description={pageDescription}
        imageUrl={
          productCollectionPage?.paImage?.asset?.url ||
          "https://cdn.sanity.io/images/gsxasun1/sheamoisture-stg-us/b6973dfe40b2db0d60c9963b580f442e826a86d2-1080x1080.jpg"
        }
        imageAlt={productCollectionPage?.paImage?.alt}
        imageWidth={imageWidth}
        imageHeight={imageHeight}
        imageMimeType={imageMimeType}
        htmlLang={htmlLang}
      />
      <>
        {sanityImage && <HeroBanner title={title} sanityImage={sanityImage} isSmallBanner={true} />}

        <Container fluid data-testid="product-page">
          <ProductCategorySearch
            categoryPageId={props.data.collectionPage.collectionId}
            sanitySiteSettings={props.pageContext.sanitySiteSettings}
            productLabels={props.data?.sanityLabels?.productLabels}
            initData={filteredProducts}
          />
        </Container>
      </>
    </Layout>
  );
};

export default ProductCollectionPage;
